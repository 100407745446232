const erpData = [
  { erpName: "ACME", "Head Office": "Satara", email: "" },
  {
    erpName: "Allied",
    "Head Office": "Pune",
    email: "sales@alliedsoftech.com",
  },
  {
    erpName: "C Square",
    "Head Office": "Bangalore",
    email: "salesenquiries@csquare.in",
  },
  { erpName: "Concept", "Head Office": "Indore", email: "" },
  { erpName: "CPS", "Head Office": "Varanasi", email: "" },
  { erpName: "Dava", "Head Office": "Pune", email: "" },
  { erpName: "Dolphin", "Head Office": "Bhubaneshwar", email: "" },
  {
    erpName: "Easysol",
    "Head Office": "Delhi",
    email: "info@excelsiortechnologies.com",
  },
  {
    erpName: "Essel / Kireetisoft",
    "Head Office": "Hyderabad?",
    email: "sales@kireetisoft.net",
  },
  { erpName: "Gofrugal", "Head Office": "Chennai", email: "" },
  { erpName: "IDM", "Head Office": "Lucknow", email: "" },
  { erpName: "Infosoft", "Head Office": "Baroda", email: "" },
  { erpName: "Kalasoft", "Head Office": "Delhi", email: "" },
  { erpName: "Kireetisoft", "Head Office": "Hyderabad?", email: "" },
  { erpName: "Kluster", "Head Office": "Kerala", email: "" },
  { erpName: "KSDS", "Head Office": "Patna", email: "" },
  { erpName: "Lifeline", "Head Office": "Kolkata", email: "" },
  {
    erpName: "Logic",
    "Head Office": "Chandigarh",
    email: "sales@logicerp.com",
  },
  { erpName: "MARG", "Head Office": "Delhi", email: "support@margerp.com" },
  { erpName: "Medi", "Head Office": "Delhi", email: "support@margerp.com" },
  {
    erpName: "Medica",
    "Head Office": "Mumbai",
    email: "torerohelp@hotmail.com",
  },
  {
    erpName: "Megasoft",
    "Head Office": "Hyderabad",
    email: "support@daxinsoft.com",
  },
  {
    erpName: "MicroPro",
    "Head Office": "Nagpur",
    email: "sales@microproindia.com",
  },
  {
    erpName: "Pharmac",
    "Head Office": "Aurangabad",
    email: "info@softmanindia.com",
  },
  { erpName: "PKS", "Head Office": "Kanpur", email: "support@pksdata.com" },
  { erpName: "PMS", "Head Office": "Patna", email: "" },
  { erpName: "Prompt", "Head Office": "Rajkot", email: "ccasia@prompt.in" },
  {
    erpName: "Reckon",
    "Head Office": "Lucknow",
    email: "admin@reckonsales.com",
  },
  { erpName: "Samarth", "Head Office": "Mumbai", email: "" },
  { erpName: "Saral", "Head Office": "Kota", email: "" },
  { erpName: "Shivashankar", "Head Office": "Trichy", email: "" },
  { erpName: "Skyway", "Head Office": "Gandhinagar", email: "" },
  { erpName: "SoftWorld", "Head Office": "Jaipur", email: "info@swindia.com" },
  { erpName: "Tally", "Head Office": "Mumbai?", email: "" },
  {
    erpName: "Wondersoft",
    "Head Office": "Chennai",
    email: "cs@wondersoft.com",
  },

  //

  // {
  //   erpName: "TEST",
  //   email: "dev@thetechclan.com",
  // },
  // {
  //   erpName: "TEST 2",
  //   email: "",
  // },

  //

  // {
  //   id: 1,
  //   erpName: "Marg",
  //   email: "ashish.k@margerp.com",
  // },
  // {
  //   id: 2,
  //   erpName: "Varthagamsoft",
  //   email: "support@varthagamsoft.in",
  // },
  // {
  //   id: 3,
  //   erpName: "Davapune",
  //   email: "davapune@davainfotech.com",
  // },
  // {
  //   id: 4,
  //   erpName: "SWIL",
  //   email: "arun@swindia.com",
  // },
  // {
  //   id: 5,
  //   erpName: "Easysol",
  //   email: "ashu@easysol.in",
  // },
  // {
  //   id: 6,
  //   erpName: "C-square",
  //   email: "afsal@c2info.com",
  // },
  // {
  //   id: 7,
  //   erpName: "AlliedSoftTech",
  //   email: "abhijeet@alliedsoftech.com",
  // },
  // {
  //   id: 8,
  //   erpName: "Soham ERP",
  //   email: "binoyshah@sohamerp.com",
  // },
  // {
  //   id: 9,
  //   erpName: "VisualInfoSoft",
  //   email: "umesh@visualinfosoft.com",
  // },
  // {
  //   id: 10,
  //   erpName: "InfoSoft",
  //   email: "soni@infosoft.co.in",
  // },
  // {
  //   id: 11,
  //   erpName: "Samarth Software",
  //   email: "samarth.software.ss@gmail.com",
  // },
  // {
  //   id: 12,
  //   erpName: "SkyWay Computer",
  //   email: "chetan@skywaycomputer.com",
  // },
  // {
  //   id: 13,
  //   erpName: "WonderSoft",
  //   email: "shankar@wondersoft.in",
  // },
  // {
  //   id: 14,
  //   erpName: "PromtIndia",
  //   email: "jaydeep@promptindia.com",
  // },
  // {
  //   id: 15,
  //   erpName: "Medica",
  //   email: "medica@torero.in",
  // },
  // {
  //   id: 16,
  //   erpName: "Logic ERP",
  //   email: "raju@logicerp.com",
  // },
  // {
  //   id: 17,
  //   erpName: "Goyal",
  //   email: "nilesh@goyalonline.com",
  // },
  // {
  //   id: 18,
  //   erpName: "Reckon",
  //   email: "aman.jain@reckonsales.com",
  // },
  // {
  //   id: 19,
  //   erpName: "KalaSoft",
  //   email: "admin@kalasoftech.com",
  // },
  // // {
  // //   id: 20,
  // //   erpName: "TEST",
  // //   email: "swapnil@allscan.in",
  // // },
];

export default erpData;
