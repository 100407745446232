import React from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import erpData from "../erpData";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedERP: "",
      selectedERPEmail: "",
      businessName: "",
      businessEmail: "",
      businessMobile: "",
      businessGST: "",
      businessAddress: "",
      userFirst: "",
      userLast: "",
      erpName: "",
      erpNAME11: "",
      allscanAgentCode: "",
    },
  });

  const onSubmit = (contactForm) => {
    // console.log("contactForm", contactForm);

    let {
      selectedERP,
      selectedERPEmail,
      businessName,
      businessEmail,
      businessMobile,
      businessGST,
      businessAddress,
      userFirst,
      userLast,
      erpName,
      erpNAME11,
      allscanAgentCode,
      radio,
    } = contactForm;

    fetch("https://l27acdgspd.execute-api.ap-south-1.amazonaws.com/sendEmail", {
      mode: "no-cors",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        senderEmail: "tech@allscan.in",
        receiverEmail:
          selectedERP !== "blank" && selectedERP !== "other"
            ? selectedERP
            : selectedERPEmail !== ""
            ? selectedERPEmail
            : "swapnil@allscan.in",
        message: `<h3>Dear ERP Partner,<h3/>
        <p>${businessName}, Type - ${radio}, GST - ${businessGST}, Address - ${businessAddress}, Contact details - ${businessEmail}, ${businessMobile}, ${userFirst} ${userLast} as requested Allscan feature in ERP.</p>
        <p>${
          erpName !== "" ? `ERP Name - ${erpName}` : `ERP Name - ${erpNAME11}`
        }</p>
        <p>${
          allscanAgentCode !== "" ? `Agent Code - ${allscanAgentCode}` : ""
        }</p>
        <p>Thanks,</p>
          AllScan team.`,
      }),
    });
    reset();
  };

  const selectedERP = register("selectedERP", {
    required: true,
  });

  const handleERPSelectChange = (e) =>
    setValue(
      "erpNAME11",
      e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text
    );

  // console.log(watch());
  return (
    <Container className="contact-form">
      <Stack gap={5}>
        <h1 style={{ alignSelf: "center" }} className="mb-3">
          Pre-Register with ERP
        </h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h4 className="mb-2">Business Details</h4>
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label> Name</Form.Label>
            <Form.Control
              name="businessName"
              placeholder="Business Name"
              {...register("businessName", {
                required: true,
                minLength: 3,
              })}
              isInvalid={!!errors.businessName}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} sm controlId="formGridPassword">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="number"
                placeholder="Mobile Number"
                {...register("businessMobile", {
                  required: true,
                  minLength: 10,
                })}
                maxLength={10}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                isInvalid={!!errors.businessMobile}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="formGridEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                {...register("businessEmail", {
                  required: true,
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                })}
                isInvalid={!!errors.businessEmail}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              className="mb-3"
              sm
              controlId="formGridPassword"
            >
              <Form.Label>GST</Form.Label>
              <Form.Control
                placeholder="GST Number"
                maxLength={15}
                {...register("businessGST", {
                  required: true,
                  pattern: {
                    value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                  },
                })}
                isInvalid={!!errors.businessGST}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="formGridEmail">
              <Form.Label>Address</Form.Label>
              <Form.Control
                placeholder="Address"
                {...register("businessAddress", {
                  required: true,
                  minLength: 3,
                })}
                isInvalid={!!errors.businessAddress}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group
              as={Col}
              className="mb-3 d-flex justify-content-around"
              sm
              controlId="formGridPassword"
            >
              <label htmlFor="field-retailer">
                <input
                  className="mx-2"
                  {...register("radio")}
                  type="radio"
                  value="Retailer"
                  id="field-retailer"
                  defaultChecked
                />
                Retailer
              </label>
              <label htmlFor="field-wholesaler">
                <input
                  className="mx-2"
                  {...register("radio")}
                  type="radio"
                  value="Wholesaler"
                  id="field-wholesaler"
                />
                Wholesaler
              </label>
              <label htmlFor="field-distributor">
                <input
                  className="mx-2"
                  {...register("radio")}
                  type="radio"
                  value="Distributor"
                  id="field-distributor"
                />
                Distributor
              </label>
              <label htmlFor="field-cfa">
                <input
                  className="mx-2"
                  {...register("radio")}
                  type="radio"
                  value="CFA"
                  id="field-cfa"
                />
                CFA
              </label>
            </Form.Group>
          </Row>
          <h4 className="mb-3">Personal Details</h4>
          <Row className="mb-3">
            <Form.Group as={Col} sm controlId="formGridPassword">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder="First Name"
                {...register("userFirst", {
                  required: true,
                  minLength: 3,
                })}
                isInvalid={!!errors.userFirst}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="formGridEmail">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder="Last Name"
                {...register("userLast", {
                  required: true,
                  minLength: 3,
                })}
                isInvalid={!!errors.userLast}
              />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" as={Col} sm controlId="formGridState">
            <Form.Label>Select ERP</Form.Label>
            <Form.Select
              // {...register("selectedERP", {
              //   required: true,
              // })}
              {...selectedERP}
              onChange={(e) => {
                selectedERP.onChange(e);
                handleERPSelectChange(e);
              }}
              aria-label="Default select example"
              isInvalid={!!errors.selectedERP}
            >
              <option value={""}>Select ERP</option>
              {erpData
                .sort((a, b) => {
                  if (a.erpName < b.erpName) {
                    return -1;
                  }
                  if (a.erpName > b.erpName) {
                    return 1;
                  }
                  return 0;
                })
                .map((data, idx) => (
                  <option key={idx} value={data.email || "blank"}>
                    {data.erpName}
                  </option>
                ))}

              <option value={"other"}>Other</option>
            </Form.Select>
          </Form.Group>
          {watch().selectedERP === "blank" ? (
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Selected ERP Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Selected ERP Email"
                {...register("selectedERPEmail", {
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                })}
                isInvalid={!!errors.selectedERPEmail}
              />
            </Form.Group>
          ) : (
            <></>
          )}

          {watch().selectedERP === "other" ? (
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>ERP Name</Form.Label>
              <Form.Control
                name="erpName"
                placeholder="ERP Name"
                {...register("erpName", {
                  required: true,
                  minLength: 3,
                })}
                isInvalid={!!errors.erpName}
              />
            </Form.Group>
          ) : (
            <></>
          )}
          <Form.Group className="mb-3" controlId="formGridAddress1">
            <Form.Label>AllScan Agent Code</Form.Label>
            <Form.Control
              name="allscanAgentCode"
              placeholder="AllScan Agent Code"
              {...register("allscanAgentCode")}
              isInvalid={!!errors.allscanAgentCode}
            />
          </Form.Group>
          <Button
            style={{
              backgroundColor: "#FF3B3A",
              border: "none",
              margin: "0 auto",
              outline: "none",
              display: "block",
            }}
            variant="primary"
            type="submit"
            className="mt-5"
          >
            Submit
          </Button>
        </Form>
      </Stack>
    </Container>
  );
};

export default ContactForm;
