import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const NavBar = () => {
  return (
    <Navbar bg="white" expand="lg">
      <Container>
        <Navbar.Brand href="http://allscan.in" target={"_blank"}>
          <img
            alt=""
            src={require("../assets/allscanLogo.png")}
            className="d-inline-block align-top"
            width={180}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto gap-4">
            <Nav.Link
              href="http://allscan.in"
              target={"_blank"}
              className="nav-link-text"
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="http://allscan.in"
              target={"_blank"}
              className="nav-link-text"
            >
              Benefits
            </Nav.Link>
            {/* <Nav.Link
              href="javascript:window.location.reload(true)"
              className="nav-link-text"
            >
              Register
            </Nav.Link> */}
            <Nav.Link
              href="http://allscan.in"
              target={"_blank"}
              className="nav-link-text"
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { NavBar as default };
