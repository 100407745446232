import {
  MDBCol,
  MDBContainer,
  MDBFooter,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React from "react";
import { FaLocationArrow, FaMailBulk } from "react-icons/fa";
import { IoCall } from "react-icons/io5";

export default function FooTer() {
  return (
    <MDBFooter
      style={{ backgroundColor: "rgb(20,20,20)" }}
      className="text-center text-lg-start text-muted"
    >
      <section className="d-flex justify-content-center justify-content-lg-between">
        {/* <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div> */}

        <div>
          <a href="" className="me-4 text-reset text-light">
            <MDBIcon color="secondary" fab icon="facebook-f" />
          </a>
          <a href="" className="me-4 text-reset text-light">
            <MDBIcon color="secondary" fab icon="twitter" />
          </a>
          <a href="" className="me-4 text-reset text-light">
            <MDBIcon color="secondary" fab icon="google" />
          </a>
          <a href="" className="me-4 text-reset text-light">
            <MDBIcon color="secondary" fab icon="instagram" />
          </a>
          <a href="" className="me-4 text-reset text-light">
            <MDBIcon color="secondary" fab icon="linkedin" />
          </a>
          <a href="" className="me-4 text-reset text-light">
            <MDBIcon color="secondary" fab icon="github" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <p className="text-light">
                <a
                  href="http://allscan.in"
                  target={"_blank"}
                  className="text-reset text-light link"
                >
                  Home
                </a>
              </p>
              {/* <p className="text-light">
                <a href="#" className="text-reset text-light link">
                  Register
                </a>
              </p> */}
              <p className="text-light">
                <a
                  href="http://allscan.in"
                  target={"_blank"}
                  className="text-reset text-light link"
                >
                  Benefits
                </a>
              </p>
              <p className="text-light">
                <a
                  href="http://allscan.in"
                  target={"_blank"}
                  className="text-reset text-light link"
                >
                  Contact
                </a>
              </p>
              <p className="text-light">Cookies | Privacy Policy</p>
            </MDBCol>
            <MDBCol
              md="3"
              lg="4"
              xl="3"
              className="mx-auto mb-4 d-flex align-items-center"
            >
              <div className="mb-3 me-4">
                <FaLocationArrow color="red" fontSize={28} />
              </div>
              <p className="text-light">
                202, Tower D, Times Square, Andheri Kurla Road, Marol, Andheri
                East, Mumbai, Maharashtra - 400059
              </p>
            </MDBCol>

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4 text-center text-md-end"
            >
              <p className="text-light">
                <MDBIcon color="secondary" icon="home" className="me-2" />
                allscan@orbo.ai {"            "}
                <FaMailBulk color="red" fontSize={28} />
              </p>
              <p className="text-light">
                <MDBIcon color="secondary" icon="envelope" className="me-3 " />
                +91 836 927 2526{"            "}
                <IoCall color="red" fontSize={28} />
              </p>
              <p className="text-light">Instagram | Facebook | LinkedIn</p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{
          color: "#000",
          background: "#fff",
          fontFamily: "sans-serif",
          fontSize: "small",
        }}
      >
        © 2023 All Scan
      </div>
    </MDBFooter>
  );
}
