import React from "react";
import ContactForm from "./components/ContactForm";
import Footer from "./components/FooTer";
import NavBar from "./components/NavBar";

const App = () => {
  return (
    <div className="app">
      <nav>
        <NavBar />
      </nav>
      <main className="main">
        <div className="form">
          <ContactForm />
        </div>
      </main>
      <footer className="footer">
        <Footer />
      </footer>
    </div>
  );
};

export default App;
